import { math } from 'polished';

export const gutter = '20px';
export const containerGutterMobile = '30px';
export const containerGutterTablet = '50px';
export const containerGutterDesktop = '70px';

// Grid
export const columnCount = 20;

// Page dimensions
export const pageWidth = '1680px';
export const pageLimit = math(`${pageWidth} + (${containerGutterDesktop} * 2)`);

// Fonts
export const fontSize = '18px';
export const fontSizeMin = '16px';

// Headings
export const h1Size = '60px';
export const h1SizeMin = '32px';

export const h2Size = '46px';
export const h2SizeMin = '28px';

export const h3Size = '24px';
export const h3SizeMin = '20px';

export const h4Size = '20px';
export const h4SizeMin = '15px';

export const h5Size = '36px';
export const h5SizeMin = '26px';

// Colors
export const colors = {
  primary: '#ffc220',
  background: '#f4f4f4',
  white: '#ffffff',
  heading: '#0a1f41',
  copy: '#6e747c',
  highlightedCopy: '#071d43',
  disabled: '#898f99',
  gray: '#4b5353',
  placeholder: '#ddd',
  border: '#e5e5e5',
  link: '#0a1f41',
  yellow: '#ffeabe',
  darkGray: '#6d7581',
  tangora: '#041e42',
  blue: '#0071dc',
};

// z-indices
export const zindexModal = 102;
export const zindexOverlay = 101;

export const segmentPaddingMobile = 20 * 3;
export const segmentPadding = '180px'; // percentage-based segments spacing
export const segmentOffset = '150px';

// Breakpoints
export const min420 = '420px';
export const min720 = '720px';
export const min840 = '840px';
export const min1025 = '1025px';
export const min1280 = '1280px';
export const min1440 = '1440px';

export const max420 = '419px';
export const max720 = '719px';
export const max840 = '839px';
export const max1025 = '1024px';
export const max1280 = '1279px';
export const max1440 = '1439px';

export const minMobile = min420;
export const minTablet = min720;
export const minDesktop = min1025;

export const maxMobile = max420;
export const maxTablet = max720;
export const maxDesktop = max1025;

export const minAffix = min1280;

// Transitions
export const transitionDurationDefault = '200ms';
export const transitionEasingDefault = 'ease-in-out';
